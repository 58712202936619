/** Main constants should store here*/
export const MainConstants = {
  LOADER_TIMEOUT: 1700,
  // LOADER_TIMEOUT: 0,
  TRAY_ICON_ID: 'tray',
  ROOM_ICON_ID: 'room',
  ROOM_SELECTOR_ROUTE: '/home/rooms',
  CATEGORY_SELECTOR_ROUTE: '/home/category',
  SUPPORT_SELECTOR_ROUTE: '/home/support',
  TRAY_ROUTE: '/home/tray',
  ITEM_SELECTOR_ROUTE: '/home/category/',
  EMPTY_STRING: '',
  TRAY_REFRESH_INTERVAL: 5000,
  TOAST_TIMEOUT: 1500,
  SUPPORT_PHONE: '+3614518398',
};
