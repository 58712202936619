import { Injectable } from '@angular/core';
import { MainConstants } from '../../../constant';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class HeaderService {
  private _backButtonRoute: string;
  private _icon: string;
  private _title: string;
  private _currentRoute: string;

  constructor(private translate: TranslateService) {
    this.backButtonRoute = null;
    this.icon = MainConstants.TRAY_ICON_ID;
    this.translate.get('ROOM.TITLE').subscribe((res: string) => {
      this.title = res;
    });
  }

  set backButtonRoute(route: string) {
    this._backButtonRoute = route;
  }

  get backButtonRoute(): string {
    return this._backButtonRoute;
  }

  set icon(route: string) {
    this._icon = route;
  }

  get icon(): string {
    return this._icon;
  }

  set title(title: string) {
    this._title = title;
  }

  get title(): string {
    return this._title;
  }

  set currentRoute(route: string) {
    this._currentRoute = route;
  }

  get currentRoute(): string {
    return this._currentRoute;
  }
}
