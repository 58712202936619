import { NgModule } from '@angular/core';
import { TrayComponent } from './tray.component';
import { TrayService } from './tray.service';
import { SharedModule } from '../shared/shared.module';
import { TrayCardComponent } from './components/tray-card/tray-card.component';
import { CancelOrderComponent } from './components/cancel-order/cancel-order.component';

@NgModule({
  imports: [SharedModule],
  declarations: [TrayComponent, TrayCardComponent, CancelOrderComponent],
  providers: [TrayService],
})
export class TrayModule {}
