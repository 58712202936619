import { Injectable, OnDestroy } from '@angular/core';
import { Order } from '../shared/classes/order';
import { Room } from '../shared/classes/room';
import { Product } from '../shared/classes/product';
import { MainConstants } from '../../constant';
import { ToastMessageService } from '../toast-message/toast-message.service';
import { ApiService } from '../../services/api.service';
import * as _ from 'underscore';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class TrayService implements OnDestroy {
  orders: Array<Order>;
  selectedRoom: Room;
  currentOrder: Order;
  selectedOrder: Order;
  interval: number;
  orderSaving = false;
  isTray: boolean;
  cancelTime: number;

  constructor(
    private apiService: ApiService,
    private toastService: ToastMessageService,
    private router: Router
  ) {
    this.orders = [];
    this.currentOrder = JSON.parse(localStorage.getItem('currentOrder')) || null;
    if (this.currentOrder) {
      this.startCancelTimeout();
    }
    this.selectedRoom = this.currentOrder && this.currentOrder.room ? this.currentOrder.room : null;
    this.selectedOrder = this.currentOrder || null;
    this.isTray = false;
  }

  ngOnDestroy(): void {
    this.stopOrderPolling();
  }

  isTrayEmpty(): boolean {
    return this.orders.length === 0 && !this.currentOrder;
  }

  setSelectedRoom(room: Room): void {
    this.selectedRoom = room;
    if (this.currentOrder) {
      this.currentOrder.room = room;
      this.saveCurrentOrderToLocalStorage();
    }
  }

  getCurrentOrder(): Order {
    if (!this.currentOrder) {
      this.currentOrder = new Order();
      this.currentOrder.room = this.selectedRoom;
    }
    return this.currentOrder;
  }

  addProductToCurrentOrder(product: Product): void {
    let item = this.findItem(product.id);
    if (item) {
      item.amount += product.amount;
    } else {
      item = {
        id: product.id,
        name: product.name,
        amount: product.amount,
        is_it: product.is_it,
      };
      this.getCurrentOrder().ordered_products.push(item);
    }
    this.toastService.showInfo('TOAST.ADDED_TO_TRAY');
    this.saveCurrentOrderToLocalStorage();
    this.startCancelTimeout();
  }

  addExtraInfoToCurrentOrder(info: string, isSupport: boolean): void {
    this.getCurrentOrder().free_texts[0].free_text = info;
    this.getCurrentOrder().free_texts[0].is_it = isSupport;
    this.saveCurrentOrderToLocalStorage();
    this.startCancelTimeout();
  }

  isExtraInfoAdded(): boolean {
    return this.currentOrder && this.currentOrder.free_texts[0].free_text.length > 0;
  }

  findItem(id: number): Product {
    const currentOrder = this.getCurrentOrder();
    for (let i = 0; i < currentOrder.ordered_products.length; i++) {
      if (currentOrder.ordered_products[i].id === id) {
        return currentOrder.ordered_products[i];
      }
    }
    return null;
  }

  setSelectedOrder(order: Order): void {
    this.selectedOrder = order;
  }

  stopOrderPolling(): void {
    clearInterval(this.interval);
    this.interval = null;
  }

  startOrderPolling(): void {
    if (!this.interval) {
      this.interval = setInterval(() => {
        this.apiService.getOrders().subscribe((data) => {
          this.orders = this.sortOrderList(data);
        });
      }, MainConstants.TRAY_REFRESH_INTERVAL);
    }
  }

  startCancelTimeout() {
    if (this.cancelTime) {
      this.endCancelTimeout();
    }
    this.cancelTime = setTimeout(() => {
      this.resetServiceAndRedirect();
    }, environment.AUTO_LOGOUT_INTERVAL);
  }

  endCancelTimeout() {
    clearTimeout(this.cancelTime);
    this.cancelTime = null;
  }

  sortOrderList(orderList: Order[]) {
    const grouped = _.groupBy(orderList, 'status');
    const retVal = [];
    Object.keys(grouped).forEach((key) => {
      grouped[key] = _.sortBy(grouped[key], (obj) => {
        return (new Date(obj['created_at']).getTime() / 1000) * -1;
      });
    });
    this.flattenArray(grouped['ORDERED'], retVal);
    this.flattenArray(grouped['BEING_MADE'], retVal);
    this.flattenArray(grouped['DELIVERED'], retVal);
    return retVal;
  }

  flattenArray(source: unknown[], target: unknown[]) {
    if (source) {
      for (let i = 0; i < source.length; i++) {
        const obj = source[i];
        target.push(obj);
      }
    }
  }

  finishOrder(): void {
    const order = {
      rooms_id: this.selectedRoom.id,
      ordered_products: [],
      free_texts:
        this.currentOrder.free_texts[0].free_text.length > 0 ? this.currentOrder.free_texts : [],
    };
    for (let i = 0; i < this.currentOrder.ordered_products.length; i++) {
      const product = this.currentOrder.ordered_products[i];
      if (product.amount > 0 || product.is_it) {
        order.ordered_products.push({
          amount: product.amount || 1,
          sub_products_id: product.id,
        });
      }
    }
    this.orderSaving = true;
    this.apiService.addOrder(order).subscribe({
      next: () => {
        this.apiService.getOrders().subscribe((data) => {
          this.orderSaving = false;
          this.orders = this.sortOrderList(data);
          this.resetServiceAndRedirect();
          this.toastService.showSucccess('TOAST.ORDER_SUCCESS');
        });
      },
      error: () => {
        this.orderSaving = false;
        this.toastService.showError('TOAST.ORDER_ERROR');
      },
    });
  }

  resetServiceAndRedirect() {
    this.currentOrder = null;
    this.endCancelTimeout();
    this.setSelectedOrder(null);
    localStorage.removeItem('currentOrder');
    this.router.navigate(['/home']);
  }

  cancelOrder(order: Order): void {
    this.apiService.deleteOrder(order.id).subscribe(() => {
      this.apiService.getOrders().subscribe((data) => {
        this.orders = this.sortOrderList(data);
        this.currentOrder = null;
        this.setSelectedOrder(null);
        this.toastService.showSucccess('TOAST.ORDER_CANCELLED');
      });
    });
  }

  saveCurrentOrderToLocalStorage(): void {
    localStorage.setItem('currentOrder', JSON.stringify(this.currentOrder));
  }

  isOrderReady(): boolean {
    let isReady = false;
    for (let i = 0; i < this.currentOrder.ordered_products.length; i++) {
      const product = this.currentOrder.ordered_products[i];
      if (product.amount > 0 || product.is_it) {
        isReady = true;
        break;
      }
    }
    if (this.currentOrder.free_texts[0].free_text.length > 0) {
      isReady = true;
    }
    return isReady;
  }

  cancelCurrentOrder(timeout: number): void {
    this.setSelectedOrder(null);
    this.currentOrder = null;
    localStorage.removeItem('currentOrder');
    setTimeout(() => {
      this.toastService.showSucccess('TOAST.ORDER_CANCELLED');
    }, timeout);
  }
}
