import { Component, OnInit } from '@angular/core';
import { MainConstants } from '../../../../constant';

@Component({
  selector: 'app-call-support-button',
  templateUrl: './call-support-button.component.html',
  styleUrls: ['./call-support-button.component.scss'],
})
export class CallSupportButtonComponent implements OnInit {
  supportPhone = 'tel:' + MainConstants.SUPPORT_PHONE;

  constructor() {}

  ngOnInit() {}
}
