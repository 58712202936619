import { CanActivateFn, Router } from '@angular/router';
import { inject } from '@angular/core';
import { MainConstants } from '../../constant';
import { AuthService } from '../auth/auth.service';

export const RoomSelectorGuard: CanActivateFn = () => {
  const authService = inject(AuthService);
  const router = inject(Router);

  if (authService.user().room) {
    router.navigate([MainConstants.CATEGORY_SELECTOR_ROUTE]);
    return false;
  }

  return true;
};
