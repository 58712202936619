import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Product } from '../../../shared/classes/product';

@Component({
  selector: 'app-add-to-tray',
  templateUrl: './add-to-tray.component.html',
  styleUrls: ['./add-to-tray.component.scss'],
})
export class AddToTrayComponent implements OnInit {
  @Input() item: Product;

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {
    if (!this.item.is_it) {
      this.item.amount = 1;
    }
  }

  increase(): void {
    this.item.amount++;
  }

  decrease(): void {
    if (this.item.amount > 1) {
      this.item.amount--;
    }
  }
}
