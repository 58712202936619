import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { ApiService } from '../../services/api.service';

@Injectable()
export class RoomResolve {
  constructor(private apiService: ApiService) {}

  resolve() {
    return this.apiService.getRooms();
  }
}
