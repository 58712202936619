import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { ItemSelectorComponent } from './item-selector.component';
import { AddToTrayComponent } from './component/add-to-tray/add-to-tray.component';
import { ItemResolve } from './item-resolve';

@NgModule({
  imports: [SharedModule],
  declarations: [ItemSelectorComponent, AddToTrayComponent],
  providers: [ItemResolve],
})
export class ItemSelectorModule {}
