import { Component, OnDestroy, OnInit } from '@angular/core';
import { MainConstants } from '../../constant';
import { HeaderService } from '../home/service/header.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { TrayService } from '../tray/tray.service';
import { Category } from '../shared/classes/category';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../auth/auth.service';

@Component({
  selector: 'app-category-selector',
  templateUrl: './category-selector.component.html',
  styleUrls: ['./category-selector.component.scss'],
})
export class CategorySelectorComponent implements OnInit, OnDestroy {
  private urlSub: Subscription;
  public isSupport: boolean;
  categories: Array<Category>;
  supportCategories: Array<Category>;

  constructor(
    private headerService: HeaderService,
    private trayService: TrayService,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private authService: AuthService
  ) {
    this.urlSub = this.route.url.subscribe((url) => {
      this.isSupport = url[0].path === 'support';
    });
  }

  ngOnInit() {
    let supportTitle, categoryTitle;
    this.translate.get('CATEGORY.TITLE').subscribe((res: string) => {
      categoryTitle = res;
    });
    this.translate.get('SUPPORT.TITLE').subscribe((res: string) => {
      supportTitle = res;
    });
    this.headerService.title = this.isSupport ? supportTitle : categoryTitle;
    this.headerService.icon = MainConstants.TRAY_ICON_ID;
    this.headerService.currentRoute = this.isSupport
      ? MainConstants.SUPPORT_SELECTOR_ROUTE
      : MainConstants.CATEGORY_SELECTOR_ROUTE;
    this.headerService.backButtonRoute = this.isSupport
      ? MainConstants.CATEGORY_SELECTOR_ROUTE
      : this.trayService.currentOrder || this.authService.user().room
        ? null
        : MainConstants.ROOM_SELECTOR_ROUTE;
    this.categories = this.route.snapshot.data['categories'];
    this.supportCategories = this.route.snapshot.data['categories'];
  }

  ngOnDestroy() {
    this.urlSub.unsubscribe();
  }
}
