import { Component, OnDestroy, OnInit } from '@angular/core';
import { HeaderService } from '../home/service/header.service';
import { MainConstants } from '../../constant';

import { TrayService } from './tray.service';
import { Order } from '../shared/classes/order';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-tray',
  templateUrl: './tray.component.html',
  styleUrls: ['./tray.component.scss'],
  animations: [
    trigger('flyInOut', [
      state('in', style({ transform: 'translateY(0)' })),
      transition('void => *', [
        style({ transform: 'translateY(-100%)' }),
        animate('0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55)'),
      ]),
      transition('* => void', [
        animate(
          '0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55)',
          style({ transform: 'translateY(-100%)' })
        ),
      ]),
    ]),
  ],
})
export class TrayComponent implements OnInit, OnDestroy {
  orders: Array<Order>;

  constructor(
    private headerService: HeaderService,
    private translate: TranslateService,
    public trayService: TrayService
  ) {}

  ngOnInit() {
    this.translate.get('TRAY.TITLE').subscribe((res: string) => {
      this.headerService.title = res;
    });
    this.headerService.icon = MainConstants.ROOM_ICON_ID;
    this.headerService.backButtonRoute = this.headerService.currentRoute;
    this.headerService.currentRoute = MainConstants.TRAY_ROUTE;
    this.trayService.setSelectedOrder(this.trayService.currentOrder || null);
    this.trayService.isTray = true;
  }

  ngOnDestroy(): void {
    this.trayService.isTray = false;
  }
}
