import { Injectable, signal } from '@angular/core';
import { Router } from '@angular/router';
import { TrayService } from '../tray/tray.service';
import { User } from '../shared/classes/user';
import { MainConstants } from '../../constant';
import { ApiService } from '../../services/api.service';
import { HeaderService } from '../home/service/header.service';

@Injectable()
export class AuthService {
  user = signal<User | null>(null);

  constructor(
    private router: Router,
    private trayService: TrayService,
    private apiService: ApiService,
    private headerService: HeaderService
  ) {}

  async login(token) {
    localStorage.setItem('auth-token', token);
    await this.checkAuth();
  }

  logout() {
    localStorage.removeItem('auth-token');
    localStorage.removeItem('currentOrder');
    this.router.navigate(['/login']);
    this.trayService.stopOrderPolling();
  }

  isLoggedIn() {
    return !!localStorage.getItem('auth-token');
  }

  getAuthToken() {
    return localStorage.getItem('auth-token');
  }

  checkAuth(): Promise<void> {
    return new Promise((resolve) => {
      if (this.isLoggedIn()) {
        this.trayService.startOrderPolling();
        this.apiService.getUserData().subscribe({
          next: (user) => {
            this.user.set(user);
            if (user.room) {
              this.trayService.setSelectedRoom(user.room);
              this.headerService.currentRoute = MainConstants.CATEGORY_SELECTOR_ROUTE;
            } else {
              this.headerService.currentRoute = MainConstants.ROOM_SELECTOR_ROUTE;
            }
            return resolve();
          },
          error: () => {
            this.headerService.currentRoute = MainConstants.ROOM_SELECTOR_ROUTE;
            return resolve();
          },
        });
      } else {
        return resolve();
      }
    });
  }
}
