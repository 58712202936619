import { NgModule } from '@angular/core';
import { HomeRoutingModule } from './home-routing.module';
import { HomeComponent } from './home.component';
import { NotFoundComponent } from '../../components/not-found/not-found.component';
import { SharedModule } from '../shared/shared.module';
import { HeaderComponent } from './components/header/header.component';
import { HeaderService } from './service/header.service';
import { CheckoutComponent } from './components/checkout/checkout.component';

@NgModule({
  imports: [HomeRoutingModule, SharedModule],
  declarations: [HomeComponent, NotFoundComponent, HeaderComponent, CheckoutComponent],
  providers: [HeaderService],
})
export class HomeModule {}
