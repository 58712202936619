import { Room } from './room';
import { Product } from './product';
import { User } from './user';

export class Order {
  id?: number;
  room?: Room;
  ordered_products: Array<Product> = [];
  free_texts?: Array<any> = [{ free_text: '', is_it: false }];
  status: string;
  created_at?: Date;
  claimed_by?: User;
  is_it?: boolean;
}
