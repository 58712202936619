import { filter } from 'rxjs/operators';
import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnDestroy {
  private loaderServiceSubscription: Subscription;
  private routerSubscription: Subscription;

  constructor(private router: Router) {
    this.routerSubscription = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        document.getElementById('main-container').scrollTop = 0;
      });
  }

  ngOnDestroy(): void {
    this.loaderServiceSubscription.unsubscribe();
    this.routerSubscription.unsubscribe();
  }
}
