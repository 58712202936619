import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LoaderService, LoaderState } from './services/loader.service';
import { Subscription } from 'rxjs';
import { ToastMessageModel } from './modules/toast-message/toast-message.model';
import { MainConstants } from './constant';
import { ToastMessageService } from './modules/toast-message/toast-message.service';
import { Router } from '@angular/router';
import { AuthService } from './modules/auth/auth.service';
import { AnimationOptions } from 'ngx-lottie';

/** Main component*/
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  /** subscription to loader state change*/
  private loaderServiceSubscription: Subscription;
  /** Indicate loader is shown*/
  public showGlobalLoader: boolean;
  public lottieConfig: AnimationOptions;
  messageList: ToastMessageModel[] = [];

  /** set translate languages, instantiates other services*/
  constructor(
    private translate: TranslateService,
    private loaderService: LoaderService,
    private toastService: ToastMessageService,
    private auth: AuthService,
    private router: Router
  ) {
    translate.addLangs(['en', 'hu']);
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('en');

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    translate.use('en');

    this.lottieConfig = {
      path: 'assets/images/loading_data.json',
      autoplay: true,
      loop: true,
    };
  }

  /**
   * Subscribe to loaderState change
   * */
  ngOnInit() {
    this.loaderServiceSubscription = this.loaderService.loaderState.subscribe(
      (state: LoaderState) => {
        this.showGlobalLoader = !!state.show;
      }
    );

    this.toastService.getAlertEvent().subscribe((r) => {
      this.messageList.push(r);
      const timeout = r.customTimeout ? r.customTimeout : MainConstants.TOAST_TIMEOUT;
      setTimeout(() => {
        for (let i = 0; i < this.messageList.length; i++) {
          const obj = this.messageList[i];
          if (obj === r && !obj.isSticky) {
            this.messageList.splice(i, 1);
            break;
          }
        }
      }, timeout);
    });

    if (this.auth.isLoggedIn()) {
      this.router.navigate(['/home/rooms']);
    }

    const isSafari =
      navigator.vendor &&
      navigator.vendor.indexOf('Apple') > -1 &&
      navigator.userAgent &&
      !navigator.userAgent.match('CriOS');
    if (isSafari) {
      document.body.addEventListener('touchstart', this.preventZoom);
      document.addEventListener('gesturestart', function (e) {
        e.preventDefault();
      });
    }
  }

  /**
   * disable safari double click zoom
   */
  preventZoom(e) {
    const t2 = e.timeStamp;
    const t1 = e.currentTarget.dataset.lastTouch || t2;
    const dt = t2 - t1;
    const fingers = e.touches.length;
    e.currentTarget.dataset.lastTouch = t2;
    if (!dt || dt > 500 || fingers > 1) {
      return;
    } // not double-tap
    e.preventDefault();
    e.target.click();
  }
}
