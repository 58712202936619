import { Component, OnDestroy, OnInit } from '@angular/core';
import { HeaderService } from '../home/service/header.service';
import { MainConstants } from '../../constant';
import { TrayService } from '../tray/tray.service';
import { Room } from '../shared/classes/room';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-room-selector',
  templateUrl: './room-selector.component.html',
  styleUrls: ['./room-selector.component.scss'],
})
export class RoomSelectorComponent implements OnInit, OnDestroy {
  private queryParamSub: Subscription;
  rooms: Array<Room>;
  private roomChange: boolean;

  constructor(
    private headerService: HeaderService,
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private trayService: TrayService
  ) {
    this.queryParamSub = this.route.queryParams.subscribe((params) => {
      this.roomChange = params['roomChange'];
    });
  }

  ngOnInit() {
    this.translate.get('ROOM.TITLE').subscribe((res: string) => {
      this.headerService.title = res;
    });
    this.headerService.icon = MainConstants.TRAY_ICON_ID;
    this.headerService.backButtonRoute = this.roomChange ? MainConstants.TRAY_ROUTE : null;
    if (!this.roomChange && !localStorage.getItem('currentOrder')) {
      this.trayService.setSelectedRoom(null);
      this.headerService.currentRoute = MainConstants.ROOM_SELECTOR_ROUTE;
    }
    this.rooms = this.route.snapshot.data['rooms'];
  }

  ngOnDestroy() {
    this.queryParamSub.unsubscribe();
  }

  selectRoom(room: Room): void {
    this.trayService.setSelectedRoom(room);
    if (this.roomChange) {
      this.router.navigate([MainConstants.TRAY_ROUTE]);
    } else {
      this.router.navigate([MainConstants.CATEGORY_SELECTOR_ROUTE]);
    }
  }
}
