// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content-container {
  position: absolute;
  top: 69px;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-x: hidden;
  overflow-y: auto;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/home/home.component.scss","webpack://./src/_variables.scss"],"names":[],"mappings":"AAEA;EACE,kBAAA;EACA,SCIoB;EDHpB,SAAA;EACA,OAAA;EACA,QAAA;EACA,kBAAA;EACA,gBAAA;AADF","sourcesContent":["@import 'variables';\n\n.content-container {\n  position: absolute;\n  top: $header-total-height;\n  bottom: 0;\n  left: 0;\n  right: 0;\n  overflow-x: hidden;\n  overflow-y: auto;\n}\n","$primary: #ffcd00;\n$secondary: #00a3ae;\n$dark: #707070;\n$base-font-size: 14px;\n\n$header-top-height: 48px;\n$header-bottom-height: 21px;\n$header-border-radius: 24px;\n$header-total-height: $header-top-height + $header-bottom-height;\n$tray-radius: 16px;\n\n$input-border-radius: 24px;\n$input-side-padding: 25px;\n$header-top-color: #ffffff;\n$header-bottom-color: #ededed;\n$text-color: #6b645e;\n$login-bg-color: #a5a498;\n$card-bg-color: #f5f5f5;\n$info-text-color: #a5a498;\n$support-card-bg-color: #7ed0d6;\n$support-card-inactive-color: #bcd8df;\n$support-card-text-color: #f5f5f5;\n$category-border-radius: 16px;\n$product-border-radius: 8px;\n$textarea-border-color: #bcd8df;\n$button-border-radius: 48px;\n$support-button-size: 84px;\n$support-button-margin: 24px;\n$gradient-height: 10%;\n$body-bottom-margin: 15%;\n$input-border-color: #edbf00;\n$image-text-bg-opacity: 0.7;\n$primary-btn-border: rgba(255, 205, 0, 1);\n$fade-bg-color: #ffffffb3;\n\n@mixin set-flex($number) {\n  flex: $number;\n}\n\n@function calculateRem($size) {\n  $remSize: calc($size / $base-font-size);\n  @return #{$remSize}rem;\n}\n\n@mixin pixelToRem($attribute, $size) {\n  #{$attribute}: calculateRem($size);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
