import { Component, OnDestroy, OnInit } from '@angular/core';
import { MainConstants } from '../../constant';
import { HeaderService } from '../home/service/header.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { AddToTrayComponent } from './component/add-to-tray/add-to-tray.component';
import { TrayService } from '../tray/tray.service';
import { Product } from '../shared/classes/product';
import { TranslateService } from '@ngx-translate/core';
import { ToastMessageService } from '../toast-message/toast-message.service';
import { ApiService } from '../../services/api.service';

@Component({
  selector: 'app-item-selector',
  templateUrl: './item-selector.component.html',
  styleUrls: ['./item-selector.component.scss'],
})
export class ItemSelectorComponent implements OnInit, OnDestroy {
  private routeParamSub: Subscription;
  private urlSub: Subscription;
  private categoryId: string;
  isSupport: boolean;
  selectedProduct: Product = new Product();
  items: Array<Product> = [];
  extraInfo: string;

  constructor(
    private headerService: HeaderService,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private translate: TranslateService,
    private apiService: ApiService,
    private toastService: ToastMessageService,
    public trayService: TrayService,
    private router: Router
  ) {
    this.routeParamSub = this.route.params.subscribe((params) => {
      this.categoryId = params['id'];
    });
    this.urlSub = this.route.url.subscribe((url) => {
      this.isSupport = url[0].path === 'support';
    });
  }

  ngOnInit() {
    this.headerService.icon = MainConstants.TRAY_ICON_ID;
    this.headerService.backButtonRoute = this.isSupport
      ? MainConstants.SUPPORT_SELECTOR_ROUTE
      : MainConstants.CATEGORY_SELECTOR_ROUTE;
    this.headerService.currentRoute = this.headerService.backButtonRoute + '/' + this.categoryId;
    this.items = this.route.snapshot.data['items'];
  }

  ngOnDestroy() {
    this.routeParamSub.unsubscribe();
    this.urlSub.unsubscribe();
  }

  onItemClick(item) {
    const options = {
      windowClass: 'mobile-modal',
      backdrop: 'static',
    };
    if (this.isSupport) {
      item.is_it = true;
    } else if (!item.amount) {
      item.amount = 1;
    }
    const modalRef = this.modalService.open(AddToTrayComponent, <NgbModalOptions>options);
    modalRef.componentInstance.item = item;
    modalRef.result.then((data) => {
      if (data) {
        this.trayService.addProductToCurrentOrder(data);
      }
    });
  }

  addExtraInfo(): void {
    this.trayService.addExtraInfoToCurrentOrder(this.extraInfo, this.isSupport);
    this.toastService.showInfo('TOAST.ADDED_TO_TRAY');
  }
}
