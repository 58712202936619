import { Component, Input, OnInit } from '@angular/core';
import { ToastMessageModel } from './toast-message.model';
import { animate, state, style, transition, trigger } from '@angular/animations';

@Component({
  selector: 'app-toast-message',
  templateUrl: './toast-message.component.html',
  styleUrls: ['./toast-message.component.scss'],
  animations: [
    trigger('flyInOut', [
      state('in', style({ opacity: 1 })),
      transition('void => *', [style({ opacity: 0 }), animate(200)]),
      transition('* => void', [animate(200, style({ opacity: 0 }))]),
    ]),
  ],
})
export class ToastMessageComponent implements OnInit {
  constructor() {}

  @Input() alerts: ToastMessageModel[] = [];

  ngOnInit() {}

  closeAlert(alert: any) {
    this.alerts.splice(this.alerts.indexOf(alert), 1);
  }
}
