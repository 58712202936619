import { Component } from '@angular/core';
import { HeaderService } from '../../service/header.service';
import { TrayService } from '../../../tray/tray.service';
import { MainConstants } from '../../../../constant';
import { AuthService } from '../../../auth/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  constructor(
    public headerService: HeaderService,
    public trayService: TrayService,
    public authService: AuthService
  ) {}

  get showHomeButton(): boolean {
    if (this.authService.user().room) {
      return this.headerService.currentRoute !== MainConstants.CATEGORY_SELECTOR_ROUTE;
    }
    return this.headerService.currentRoute !== MainConstants.ROOM_SELECTOR_ROUTE;
  }
}
