// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.
/** environment to development, set production, apiUrl values*/
export const environment = {
  production: false,
  apiUrl: 'https://api.ey-catering-testing.attrecto-dev.com/api/en',
  AUTO_LOGOUT_INTERVAL: 900000,
};
