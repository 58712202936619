import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

/** Store loader shown property*/
@Injectable({ providedIn: 'root' })
export class LoaderService {
  /** Store show property*/
  private loaderSubject = new BehaviorSubject<LoaderState>({ show: 0 });
  /** Store observable, from other components can subscribe to it*/
  loaderState = this.loaderSubject.asObservable();

  /** Set default state of show, set to true*/
  constructor() {
  }

  /** set show value to true*/
  show() {
    this.loaderSubject.next(<LoaderState>{ show: ++this.loaderSubject.value.show });
  }

  /** set show value to false*/
  hide() {
    this.loaderSubject.next(<LoaderState>{ show: --this.loaderSubject.value.show });
  }
}

/** Specify LoaderState properties*/
export interface LoaderState {
  /** store show value*/
  show: number;
}
