// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader-hidden {
  visibility: hidden;
}

.loader-overlay {
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  opacity: 1;
  z-index: 500000;
  background: rgba(255, 255, 255, 0.5);
  -webkit-backdrop-filter: blur(2px);
          backdrop-filter: blur(2px);
}`, "",{"version":3,"sources":["webpack://./src/app/app.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;AACF;;AAEA;EACE,kBAAA;EACA,SAAA;EACA,MAAA;EACA,OAAA;EACA,QAAA;EACA,UAAA;EACA,eAAA;EACA,oCAAA;EACA,kCAAA;UAAA,0BAAA;AACF","sourcesContent":[".loader-hidden {\n  visibility: hidden;\n}\n\n.loader-overlay {\n  position: absolute;\n  bottom: 0;\n  top: 0;\n  left: 0;\n  right: 0;\n  opacity: 1;\n  z-index: 500000;\n  background: rgba(255, 255, 255, 0.5);\n  backdrop-filter: blur(2px);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
