import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SupportButtonComponent } from './components/support-button/support-button.component';
import { CallSupportButtonComponent } from './components/call-support-button/call-support-button.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { ToastMessageModule } from '../toast-message/toast-message.module';
import { LottieModule } from 'ngx-lottie';
import player from 'lottie-web';

export function playerFactory() {
  return player;
}

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    MatGridListModule,
    LottieModule.forRoot({ player: playerFactory }),
    ToastMessageModule,
  ],
  declarations: [SupportButtonComponent, CallSupportButtonComponent],
  exports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    SupportButtonComponent,
    CallSupportButtonComponent,
    MatGridListModule,
    ToastMessageModule,
    LottieModule,
  ],
  providers: [],
})
export class SharedModule {}
