import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ApiService } from '../../services/api.service';

@Injectable()
export class CategoryResolve {
  constructor(private apiService: ApiService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (state.url.indexOf('support') === -1) {
      return this.apiService.getProducts();
    } else {
      return this.apiService.getItProducts();
    }
  }
}
