import { NgModule } from '@angular/core';
import { RoomSelectorComponent } from './room-selector.component';
import { SharedModule } from '../shared/shared.module';
import { RoomResolve } from './room-resolve';

@NgModule({
  imports: [SharedModule],
  declarations: [RoomSelectorComponent],
  providers: [RoomResolve],
})
export class RoomSelectorModule {}
