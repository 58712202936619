// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `app-not-found .jumbotron {
  height: 100svh;
  border-radius: 0;
}
app-not-found .logo svg {
  height: 128px;
}
app-not-found .logo svg .st1 {
  fill: #000000;
}`, "",{"version":3,"sources":["webpack://./src/app/components/not-found/not-found.component.scss"],"names":[],"mappings":"AACE;EACE,cAAA;EACA,gBAAA;AAAJ;AAGI;EACE,aAAA;AADN;AAEM;EACE,aAAA;AAAR","sourcesContent":["app-not-found {\n  .jumbotron {\n    height: 100svh;\n    border-radius: 0;\n  }\n  .logo {\n    svg {\n      height: 128px;\n      .st1 {\n        fill: #000000;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
