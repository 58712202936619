import { tap } from 'rxjs/operators';

import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest, HttpResponse,
} from '@angular/common/http';
import { LoaderService } from '../../services/loader.service';
import { Observable } from 'rxjs';
import { Injectable, Injector } from '@angular/core';
import { environment } from '../../../environments/environment';
import { AuthService } from '../auth/auth.service';
import { MainConstants } from '../../constant';
import { ToastMessageService } from '../toast-message/toast-message.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class WsInterceptor implements HttpInterceptor {
  private customErrorWs = ['login'];

  constructor(
    private loader: LoaderService,
    private injector: Injector,
    private toastService: ToastMessageService
  ) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const url = req.urlWithParams;
    const apiCall =
      url.indexOf(environment.apiUrl) > -1 &&
      (url.indexOf('orders') === -1 || (url.indexOf('orders') > -1 && req.method !== 'GET'));

    if (apiCall) {
      this.loader.show();
    }
    return next.handle(req).pipe(
      tap(
        (event) => {
          if (event instanceof HttpResponse) {
            // handle succesful calls
            this.hideLoader(apiCall);
          }
        },
        (err) => {
          this.hideLoader(apiCall);
          if (err instanceof HttpErrorResponse) {
            this.handleError(err);
          }
        }
      )
    );
  }

  hideLoader(apiCall) {
    if (apiCall) {
      setTimeout(() => {
        this.loader.hide();
      }, MainConstants.LOADER_TIMEOUT);
    }
  }

  handleError(err) {
    const translate = this.injector.get(TranslateService);
    if (err.status === 401) {
      const auth = this.injector.get(AuthService);
      this.toastService.showError('TOAST.UNAUTHORIZED_REQUEST');
      auth.logout();
    } else {
      if (!this.isCustomErrorWs(err.url)) {
        // translate.get('TOAST.API_ERROR').subscribe((res: string) => {
        //   this.toastService.showError(res);
        // });
        console.error(err);
      }
    }
  }

  isCustomErrorWs(url): boolean {
    let isCustom = false;
    if (url) {
      for (let i = 0; i < this.customErrorWs.length; i++) {
        const ws = this.customErrorWs[i];
        if (url.indexOf(ws) > -1) {
          isCustom = true;
          break;
        }
      }
    }
    return isCustom;
  }
}
