import { Component, OnDestroy, OnInit } from '@angular/core';
import { TrayService } from '../../../tray/tray.service';

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss'],
})
export class CheckoutComponent {
  constructor(public trayService: TrayService) {}
}
