import { NgModule } from '@angular/core';
import { CategorySelectorComponent } from './category-selector.component';
import { SharedModule } from '../shared/shared.module';
import { CategoryResolve } from './category-resolve';

@NgModule({
  imports: [SharedModule],
  declarations: [CategorySelectorComponent],
  providers: [CategoryResolve],
})
export class CategorySelectorModule {}
