import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ApiService } from '../../services/api.service';

@Injectable()
export class ItemResolve {
  constructor(private apiService: ApiService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (state.url.indexOf('support') === -1) {
      return this.apiService.getItems(route.paramMap.get('id'));
    } else {
      return this.apiService.getItItems(route.paramMap.get('id'));
    }
  }
}
