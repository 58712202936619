import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { Observable } from 'rxjs';
import { User } from '../modules/shared/classes/user';

@Injectable({ providedIn: 'root' })
export class ApiService {
  constructor(private httpService: HttpService) {}

  login(email: string): Observable<any> {
    const requestBody = {
      email: email,
    };
    return this.httpService.post('/login', requestBody);
  }

  confirmLogin(confirmString: string, email: string): Observable<any> {
    const requestBody = {
      email_token: confirmString,
      email: email,
    };
    return this.httpService.post('/confirm-login', requestBody);
  }

  getUserData(): Observable<User> {
    return this.httpService.get('/users/me');
  }

  getOrders(): Observable<any> {
    return this.httpService.get('/orders', {});
  }

  addOrder(order): Observable<any> {
    return this.httpService.post('/orders', order);
  }

  deleteOrder(orderId: number): Observable<any> {
    return this.httpService.delete('/orders/' + orderId);
  }

  getRooms(): Observable<any> {
    return this.httpService.get('/rooms', {});
  }

  getProducts(): Observable<any> {
    return this.httpService.get('/products', {});
  }

  getItProducts(): Observable<any> {
    return this.httpService.get('/it-products', {});
  }

  getItems(id: string): Observable<any> {
    const url = '/products/' + id + '/sub-products';
    return this.httpService.get(url, {});
  }

  getItItems(id: string): Observable<any> {
    const url = '/it-products/' + id + '/sub-products';
    return this.httpService.get(url, {});
  }
}
