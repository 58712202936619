import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { HomeComponent } from './modules/home/home.component';
import { AuthGuard } from './modules/auth/auth.guard';
import { RoomSelectorComponent } from './modules/room-selector/room-selector.component';
import { TrayComponent } from './modules/tray/tray.component';
import { CategorySelectorComponent } from './modules/category-selector/category-selector.component';
import { ItemSelectorComponent } from './modules/item-selector/item-selector.component';
import { RoomResolve } from './modules/room-selector/room-resolve';
import { CategoryResolve } from './modules/category-selector/category-resolve';
import { ItemResolve } from './modules/item-selector/item-resolve';
import { RoomSelectorGuard } from './modules/room-selector/room-selector.guard';

const routes: Routes = [
  { path: 'login', component: LoginComponent, canActivate: [AuthGuard] },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: 'rooms',
        component: RoomSelectorComponent,
        resolve: {
          rooms: RoomResolve,
        },
        canActivate: [RoomSelectorGuard],
      },
      {
        path: 'tray',
        component: TrayComponent,
      },
      {
        path: 'category',
        component: CategorySelectorComponent,
        resolve: {
          categories: CategoryResolve,
        },
      },
      {
        path: 'category/:id',
        component: ItemSelectorComponent,
        resolve: {
          items: ItemResolve,
        },
      },
      {
        path: 'support',
        component: CategorySelectorComponent,
        resolve: {
          categories: CategoryResolve,
        },
      },
      {
        path: 'support/:id',
        component: ItemSelectorComponent,
        resolve: {
          items: ItemResolve,
        },
      },
      { path: '', redirectTo: 'rooms', pathMatch: 'full' },
    ],
  },
  { path: '**', redirectTo: 'login' },
];

/** Main routing module*/
export const AppRoutingModule = RouterModule.forRoot(routes);
