import { Component, OnDestroy, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../modules/auth/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ApiService } from '../../services/api.service';
import { ToastMessageService } from '../../modules/toast-message/toast-message.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LoginComponent implements OnDestroy {
  loginForm = new FormGroup<{ email: FormControl<string> }>({
    email: new FormControl<string>('', Validators.minLength(1)),
  });
  confirmForm = new FormGroup<{
    email: FormControl<string>;
    pin: FormControl<string>;
  }>({
    pin: new FormControl<string>('', [Validators.minLength(4), Validators.maxLength(6)]),
    email: new FormControl<string>('', Validators.minLength(1)),
  });
  private routeParamSub: Subscription;
  public confirm: boolean;

  constructor(
    private auth: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private apiService: ApiService,
    private toastService: ToastMessageService
  ) {
    this.routeParamSub = this.route.queryParams.subscribe((params) => {
      this.confirm = params['pinConfirm'];
      if (this.confirm && !this.auth.isLoggedIn()) {
        this.confirmForm.controls['email'].setValue(params['email']);
        this.confirmForm.controls['pin'].setValue('');
      } else {
        this.router.navigate(['/home']);
      }
    });
  }

  ngOnDestroy(): void {
    this.routeParamSub.unsubscribe();
  }

  confirmLogin(): void {
    this.apiService
      .confirmLogin(this.confirmForm.get('pin').value, this.confirmForm.get('email').value)
      .subscribe(
        (res) => {
          this.auth.login(res.api_token).then(() => {
            this.router.navigate(['/home']);
          });
        },
        () => {
          this.toastService.showError('TOAST.WRONG_PIN_CODE', 4000);
        }
      );
  }

  onLogin() {
    this.apiService.login(this.loginForm.get('email').value).subscribe({
      next: (res) => {
        if (res['success']) {
          this.toastService.showSucccess('TOAST.EMAIL_SENT', 4000);
          this.router.navigate([], {
            queryParams: {
              pinConfirm: true,
              email: this.loginForm.get('email').value,
            },
          });
        }
      },
      error: (error) => {
        if (error.status === 403) {
          this.toastService.showError('TOAST.INACTIVE_USER', 4000);
        } else {
          this.toastService.showError('TOAST.USE_EY_EMAIL', 4000);
        }
      },
    });
  }
}
