import { Component, Input } from '@angular/core';
import { Order } from '../../../shared/classes/order';
import { TrayService } from '../../tray.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { CancelOrderComponent } from '../cancel-order/cancel-order.component';
import { AuthService } from '../../../auth/auth.service';

@Component({
  selector: 'app-tray-card',
  templateUrl: './tray-card.component.html',
  styleUrls: ['./tray-card.component.scss'],
  animations: [
    trigger('flyOut', [
      state('out', style({ transform: 'translateX(0)', opacity: 1, height: '48px' })),
      transition('* => void', [
        animate(
          '0.3s cubic-bezier(0.6, -0.28, 0.735, 0.045)',
          style({ transform: 'translateX(100%)', opacity: 0, height: 0 })
        ),
      ]),
    ]),
  ],
})
export class TrayCardComponent {
  @Input() order: Order;
  editing = false;

  constructor(
    public authService: AuthService,
    public trayService: TrayService,
    private modalService: NgbModal
  ) {}

  increase(item): void {
    item.amount++;
    this.trayService.startCancelTimeout();
  }

  decrease(item): void {
    if (item.amount > 0) {
      item.amount--;
      this.trayService.startCancelTimeout();
    }
  }

  remove(item): void {
    for (let i = 0; i < this.order.ordered_products.length; i++) {
      const product = this.order.ordered_products[i];
      if (product.id === item.id) {
        this.order.ordered_products.splice(i, 1);
        break;
      }
    }
    this.trayService.startCancelTimeout();
  }

  editExtraInfo(): void {
    this.editing = !this.editing;
    this.trayService.startCancelTimeout();
  }

  clearExtraInfo(): void {
    this.trayService.addExtraInfoToCurrentOrder('', false);
    this.trayService.startCancelTimeout();
  }

  onClick(event, order, type): void {
    event.stopPropagation();
    switch (type) {
      case 'cancel':
        this.showConfirmModal(order);
        break;
      case 'select':
        this.trayService.setSelectedOrder(order);
        break;
      case 'order':
        this.trayService.finishOrder();
        break;
      case 'cancelCurrent':
        this.trayService.cancelCurrentOrder(0);
        break;
      default:
        break;
    }
  }

  showConfirmModal(order) {
    const options = {
      windowClass: 'mobile-modal',
      backdrop: 'static',
    };
    const modalRef = this.modalService.open(CancelOrderComponent, <NgbModalOptions>options);
    modalRef.result.then((confirm) => {
      if (confirm) {
        this.trayService.cancelOrder(order);
      }
    });
  }
}
